<template>
  <c-list-item :marginRight="[null, '30px']" :display="['none', 'flex']">
    <c-menu v-slot="{ isOpen }" pos="relative">
      <c-menu-button
        display="flex"
        backgroundColor="transparent"
        flexDirection="column"
        textAlign="center"
        h="auto"
        :is-active="isOpen"
        v-chakra="{
          ':hover': {
            backgroundColor: 'transparent',
          },
          ':focus': {
            outline: 'none',
            boxShadow: 'none',
            background: 'none !important',
          },
        }"
      >
        <c-image
          v-if="isOpen"
          :src="require('@/assets/icon-brand-asana-active.svg')"
          mx="auto"
          mb="5px"
        />
        <c-image
          v-else
          :src="require('@/assets/icon-brand-asana.svg')"
          mx="auto"
          mb="5px"
        />
        <c-text
          fontFamily="Roboto"
          fontSize="12px"
          lineHeight="18px"
          fontWeight="400"
          :color="isOpen ? 'brand.900' : ''"
        >
          Menu
        </c-text>
      </c-menu-button>
      <c-menu-list
        ml="-290px !important"
        mt="18px !important"
        w="658px"
        boxShadow="0px 5px 30px 0px #00000026"
        border="none"
        z-index="999"
      >
        <c-flex
          flexWrap="wrap"
          justify-content="flex-start"
          align-items="center"
          p="25px"
          grid-gap="15px"
        >
          <header-menus-item
            title="Program"
            icon="/images/icon-stack.png"
            @click="$router.push({ name: 'client.profile.program' })"
          />
          <header-menus-item
            title="Ahli Gizi"
            icon="/images/icon-users.png"
            @click="$router.push({ name: 'client.nutritionists' })"
          />
          <header-menus-item
            title="Meal Plan"
            icon="/images/icon-meat.png"
            @click="$router.push('/meal-plan')"
          />
          <header-menus-item
            title="Diet Coaching &amp; Challenge"
            icon="/images/icon-run.png"
            @click="$router.push('/content-challenge')"
          />
          <header-menus-item
            title="Progress &amp; Tracker"
            icon="/images/icon-chart-line.png"
            @click="$router.push('/progress-tracker')"
          />
          <header-menus-item
            title="Follow Up Note"
            icon="/images/icon-notes.png"
            @click="$router.push({ name: 'client.follow-up-notes' })"
          />
          <header-menus-item
            title="Glossary"
            :icon="require('@/assets/ic-menus-glossary.svg')"
            @click="$router.push('/glossaries')"
          />
        </c-flex>
      </c-menu-list>
    </c-menu>
  </c-list-item>
</template>

<script>
import HeaderMenusItem from "./header-menus-item.vue"

export default {
  components: { HeaderMenusItem },
  name: "HeaderMenus",
  props: { role: String },
}
</script>
