var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('c-list-item',{attrs:{"marginRight":[null, '30px'],"textAlign":"center"}},[_c('c-menu',{attrs:{"pos":"relative","zIndex":"99"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isOpen = ref.isOpen;
return [_c('c-menu-button',{directives:[{name:"chakra",rawName:"v-chakra",value:({
        ':hover': {
          backgroundColor: 'transparent',
        },
        ':focus': {
          outline: 'none',
          boxShadow: 'none',
          background: 'none !important',
        },
      }),expression:"{\n        ':hover': {\n          backgroundColor: 'transparent',\n        },\n        ':focus': {\n          outline: 'none',\n          boxShadow: 'none',\n          background: 'none !important',\n        },\n      }"}],attrs:{"display":"flex","backgroundColor":"transparent","flexDirection":"column","textAlign":"center","h":"auto","p":[0, null],"is-active":isOpen}},[_c('c-image',{attrs:{"src":_vm.getIcons,"mx":"auto","mb":[null, '5px']}}),_c('c-text',{attrs:{"display":['none', 'block'],"color":_vm.unreadNotification || isOpen ? 'brand.900' : '',"fontFamily":"Roboto","fontSize":"12px","lineHeight":"18px","fontWeight":"400"}},[_vm._v(" Notifikasi ")])],1),_c('c-menu-list',{attrs:{"marginLeft":[null, '-238px !important'],"minWidth":['100%', '590px'],"maxWidth":['100%', '590px'],"marginTop":['10px !important', '18px !important'],"boxShadow":"0px 5px 30px 0px #00000026","border":"none","zIndex":"88"}},[_c('c-heading',{attrs:{"as":"h3","fontSize":"18px","fontWeight":"600","color":"black.900","textAlign":"left","px":"20px","py":"10px"}},[_vm._v(" Notifikasi ")]),_c('c-box',{attrs:{"max-height":"400px","overflow-y":"auto"}},_vm._l((_vm.notifications),function(item,index){return _c('c-menu-item',{directives:[{name:"chakra",rawName:"v-chakra",value:({
            ':hover': {
              backgroundColor: 'transparent',
            },
            ':focus': {
              outline: 'none',
              boxShadow: 'none',
              background: 'none !important',
            },
          }),expression:"{\n            ':hover': {\n              backgroundColor: 'transparent',\n            },\n            ':focus': {\n              outline: 'none',\n              boxShadow: 'none',\n              background: 'none !important',\n            },\n          }"}],key:index,attrs:{"borderTop":"thin solid #F2F2F2","backgroundColor":!item.isRead ? '#008c810d' : 'white'},on:{"click":function($event){return _vm.onReadNotification(item)}}},[_c('c-box',{attrs:{"pos":"relative","pl":"80px","pr":"20px","py":"20px"}},[_c('c-box',{attrs:{"as":"span","display":"inline-block","pos":"absolute","top":"20px","left":"10px"}},[(!item.isRead)?_c('c-box',{attrs:{"as":"span","display":"inline-block","pos":"absolute","top":"0","right":"-6px","borderRadius":"100%","backgroundColor":"red","w":"10px","h":"10px"}}):_vm._e(),_c('c-image',{attrs:{"src":"/images/icon-alert-circle.png","mx":"auto"}})],1),_c('c-heading',{attrs:{"fontFamily":"Roboto","fontSize":"16px","fontWeight":"500","lineHeight":"24px","color":"black.900","marginBottom":"6px"}},[_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(item.title))+" ")]),_c('c-text',{attrs:{"color":"superDarkGray.900","fontFamily":"Roboto","fontSize":"16px","marginBottom":"6px"}},[_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(item.content))+" ")]),_c('c-text',{attrs:{"color":"lightGray.900","fontFamily":"Roboto","fontSize":"16px","lineHeight":"24px"}},[_vm._v(" "+_vm._s(_vm.getFormatDate(item.createdAt))+" ")])],1)],1)}),1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }