<template>
  <c-menu-item
    w="294px"
    h="100px"
    p="8px"

    border="1px solid #008C81"
    rounded="md"
    :_hover="{
      bg: 'transparent',
    }"
    :_focus="{
      outline: 'none',
      bg: 'none',
      boxShadow: 'none',
    }"
    @click="$emit('click')"
  >
    <c-image
      :src="icon"
    />
    <c-text
      font-style="normal"
      font-weight="500"
      font-size="18px"
      line-height="150%"
      color="#111111"
    >
      {{ title }}
    </c-text>
  </c-menu-item>
</template>

<script>
export default {
  name: "header-menus-item",
  props: ["title", "icon"],
};
</script>

<style scoped></style>
