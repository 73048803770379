<template>
  <c-list
    v-if="isAuthenticated && role === 'nutritionist'"
    d="flex"
    flex-dir="row"
  >
    <c-list-item :display="['none', 'flex']" mr="30px">
      <c-link
        as="router-link"
        :to="{ name: 'content-challenge.coaching-course' }"
      >
        <header-menu-item
          :icon="require('@/assets/icon-run-black.svg')"
          label="Coaching &amp; Challenge"
        />
      </c-link>
    </c-list-item>
    <c-list-item :display="['none', 'flex']" mr="30px">
      <c-link as="router-link" :to="{ name: 'nutrisionist.managementClient' }">
        <header-menu-item
          :icon="require('@/assets/icon-users-black.svg')"
          label="Manajemen Klien"
        />
      </c-link>
    </c-list-item>

    <header-notification />

    <header-user />
  </c-list>
</template>

<script>
import HeaderMenuItem from "./header-menu-item.vue";
import HeaderUser from "./header-user.vue";
import HeaderNotification from "@/components/header/header-notification";

export default {
  name: "HeaderMenusNutritionist",
  props: { role: String, isAuthenticated: Boolean },
  components: { HeaderMenuItem, HeaderUser, HeaderNotification },
};
</script>
