<template>
  <c-list-item
    textAlign="center"
    :display="['none', 'flex']"
    alignItems="center"
  >
    <c-menu v-slot="{ isOpen }" pos="relative">
      <c-menu-button
        display="flex"
        backgroundColor="transparent"
        textAlign="center"
        :is-active="isOpen"
        right-icon="chevron-down"
        :paddingLeft="[null, '30px']"
        :paddingRight="[null, '50px']"
        borderLeft="1px solid #F2F2F2"
        v-chakra="{
          ':hover': {
            backgroundColor: 'transparent !important',
          },
          ':focus': {
            outline: 'none',
            boxShadow: 'none !important',
            background: 'none !important',
          },
        }"
      >
        <c-text
          :display="['none', 'block']"
          fontFamily="Roboto"
          fontSize="18px"
          fontWeight="400"
          color="#555555"
        >
          {{ fullName }}
        </c-text>
        <c-box
          pos="absolute"
          top="0"
          right="0"
          w="38px"
          h="38px"
          overflow="hidden"
          borderRadius="100%"
          bg="superLightGray.900"
        >
          <c-image
            w="100%"
            h="100%"
            object-fit="cover"
            :src="getPhotoUser(user.photoUrl)"
            :alt="fullName"
          />
        </c-box>
      </c-menu-button>
      <c-menu-list
        marginLeft="8px !important"
        marginTop="18px !important"
        boxShadow="0px 5px 30px 0px #00000026"
        border="none"
      >
        <c-menu-item
          px="10px"
          borderBottom="1px solid #F2F2F2"
          v-chakra="{
            ':hover': {
              backgroundColor: 'transparent !important',
            },
            ':focus': {
              outline: 'none',
              boxShadow: 'none',
              background: 'none !important',
            },
          }"
        >
          <c-link
            display="block"
            px="10px"
            py="10px"
            w="100%"
            fontSize="18px"
            color="black.900"
            as="router-link"
            :to="{ name: 'client.profile.detail' }"
          >
            Lihat Profile
          </c-link>
        </c-menu-item>
        <c-menu-item
          v-if="!role.includes('admin')"
          px="10px"
          borderBottom="1px solid #F2F2F2"
          v-chakra="{
            ':hover': {
              backgroundColor: 'transparent !important',
            },
            ':focus': {
              outline: 'none',
              boxShadow: 'none',
              background: 'none !important',
            },
          }"
        >
          <c-link
            display="block"
            px="10px"
            py="10px"
            w="100%"
            fontSize="18px"
            color="black.900"
            as="router-link"
            :to="{ name: 'client.profile.settings' }"
          >
            Pengaturan
          </c-link>
        </c-menu-item>
        <c-menu-item
          px="10px"
          v-chakra="{
            ':hover': {
              backgroundColor: 'transparent !important',
            },
            ':focus': {
              outline: 'none',
              boxShadow: 'none',
              background: 'none !important',
            },
          }"
        >
          <c-button
            @click="logout()"
            variant="ghost"
            m="0"
            w="100%"
            display="block"
            px="10px"
            py="10px"
            fontSize="18px"
            color="#FF0000"
            text-align="left"
            font-weight="normal"
          >
            Log Out
          </c-button>
        </c-menu-item>
      </c-menu-list>
    </c-menu>
  </c-list-item>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import generalMixin from "@/utils/general-mixins";
export default {
  name: "HeaderUser",
  mixins: [generalMixin],
  computed: {
    ...mapState({
      user: (s) => s.auth.user,
      role: (s) => s.auth.user?.role,
    }),
    ...mapGetters({
      fullName: "auth/fullName",
    }),
  },
  methods: {
    ...mapActions({
      signout: "auth/signout",
    }),
    logout() {
      this.signout()
        .then(() => {
          this.$router.replace("/");
          if (this.$route.name.includes("admin")) {
            this.$router.replace("/login-empty");
          }
        })
        .catch(() => {
          this.$router.replace("/");
        });
    },
  },
};
</script>
