<template>
  <c-box
    class="header"
    bgColor="white"
    w="100%"
    :px="['20px', '30px']"
    py="20px"
    boxShadow="0px 5px 30px 0px #0000000D"
    position="sticky"
    top="0"
    z-index="10"
  >
    <c-flex
      w="100%"
      maxWidth="1200px"
      mx="auto"
      justifyContent="space-between"
      align="center"
    >
      <c-link
        v-if="logo"
        :d="['flex', 'none']"
        alignItems="center"
        font-size="16px"
        font-weight="600"
        font-family="Montserrat"
      >
        <c-button
          v-if="back"
          variant-color="brand"
          variant="link"
          ml="-15px"
          @click="onBack"
        >
          <c-icon name="chevron-left" color="green" size="26px" />
        </c-button>
        {{ backTitle }}
      </c-link>
      <c-link v-if="!logo || !isMobile" as="router-link" to="/">
        <logo-colored />
      </c-link>
      <c-flex align="center">
        <c-list
          v-if="isAuthenticated && role === 'client'"
          display="flex"
          alignItems="center"
          h="auto"
        >
          <header-menus />
          <header-notification />
          <header-keranjang />
          <header-user />
        </c-list>
        <header-menus-nutritionist
          v-if="isAuthenticated && role === 'nutritionist'"
          :is-authenticated="isAuthenticated"
          :role="role"
        />
        <c-list
          v-if="isAuthenticated && role.includes('admin')"
          display="flex"
          alignItems="center"
          h="auto"
        >
          <header-user :role="role" mr="unset" />
        </c-list>
        <c-link
          v-if="!isAuthenticated"
          as="router-link"
          :to="{ name: 'auth.login-empty' }"
          px="30px"
          py="10px"
          color="white"
          bgColor="brand.900"
          borderRadius="100px"
          textTransform="uppercase"
          fontSize="16px"
          fontWeight="700"
        >
          Mulai
        </c-link>
      </c-flex>
    </c-flex>
  </c-box>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import LogoColored from "../logo-colored.vue";
import HeaderKeranjang from "./header-keranjang.vue";
import HeaderMenus from "./header-menus.vue";
import HeaderNotification from "./header-notification.vue";
import HeaderUser from "./header-user.vue";
import HeaderMenusNutritionist from "./header-menu-nutritionist.vue";

export default {
  name: "Header",
  props: ["logo", "back", "backTitle"],
  components: {
    LogoColored,
    HeaderMenus,
    HeaderKeranjang,
    HeaderNotification,
    HeaderUser,
    HeaderMenusNutritionist,
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated",
    }),
    ...mapState({
      user: (s) => s.auth.user,
      role: (s) => s.auth.user?.role,
    }),
    isMobile() {
      let match = window.matchMedia("(min-width: 30em)");

      if (match.matches) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    onBack() {
      if (typeof this.back === "string" && this.back.includes(".")) {
        this.$router.push({ name: this.back });
      } else {
        this.$router.back();
      }
    },
  },
};
</script>
