<template>
  <c-list-item :marginRight="[null, '30px']" textAlign="center">
    <c-menu v-slot="{ isOpen }" pos="relative" zIndex="99">
      <c-menu-button
        display="flex"
        backgroundColor="transparent"
        flexDirection="column"
        textAlign="center"
        h="auto"
        :p="[0, null]"
        :is-active="isOpen"
        v-chakra="{
          ':hover': {
            backgroundColor: 'transparent',
          },
          ':focus': {
            outline: 'none',
            boxShadow: 'none',
            background: 'none !important',
          },
        }"
      >
        <c-image :src="getIcons" mx="auto" :mb="[null, '5px']" />
        <c-text
          :display="['none', 'block']"
          :color="unreadNotification || isOpen ? 'brand.900' : ''"
          fontFamily="Roboto"
          fontSize="12px"
          lineHeight="18px"
          fontWeight="400"
        >
          Notifikasi
        </c-text>
      </c-menu-button>
      <c-menu-list
        :marginLeft="[null, '-238px !important']"
        :minWidth="['100%', '590px']"
        :maxWidth="['100%', '590px']"
        :marginTop="['10px !important', '18px !important']"
        boxShadow="0px 5px 30px 0px #00000026"
        border="none"
        zIndex="88"
      >
        <c-heading
          as="h3"
          fontSize="18px"
          fontWeight="600"
          color="black.900"
          textAlign="left"
          px="20px"
          py="10px"
        >
          Notifikasi
        </c-heading>

        <c-box max-height="400px" overflow-y="auto">
          <c-menu-item
            v-for="(item, index) in notifications"
            :key="index"
            @click="onReadNotification(item)"
            borderTop="thin solid #F2F2F2"
            :backgroundColor="!item.isRead ? '#008c810d' : 'white'"
            v-chakra="{
              ':hover': {
                backgroundColor: 'transparent',
              },
              ':focus': {
                outline: 'none',
                boxShadow: 'none',
                background: 'none !important',
              },
            }"
          >
            <c-box pos="relative" pl="80px" pr="20px" py="20px">
              <c-box
                as="span"
                display="inline-block"
                pos="absolute"
                top="20px"
                left="10px"
              >
                <c-box
                  v-if="!item.isRead"
                  as="span"
                  display="inline-block"
                  pos="absolute"
                  top="0"
                  right="-6px"
                  borderRadius="100%"
                  backgroundColor="red"
                  w="10px"
                  h="10px"
                />
                <c-image src="/images/icon-alert-circle.png" mx="auto" />
              </c-box>
              <c-heading
                fontFamily="Roboto"
                fontSize="16px"
                fontWeight="500"
                lineHeight="24px"
                color="black.900"
                marginBottom="6px"
              >
                {{ capitalizeFirstLetter(item.title) }}
              </c-heading>
              <c-text
                color="superDarkGray.900"
                fontFamily="Roboto"
                fontSize="16px"
                marginBottom="6px"
              >
                {{ capitalizeFirstLetter(item.content) }}
              </c-text>
              <c-text
                color="lightGray.900"
                fontFamily="Roboto"
                fontSize="16px"
                lineHeight="24px"
              >
                {{ getFormatDate(item.createdAt) }}
              </c-text>
            </c-box>
          </c-menu-item>
        </c-box>
      </c-menu-list>
    </c-menu>
  </c-list-item>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "HeaderNotification",
  computed: {
    ...mapGetters({
      axios: "axios",
      notifications: "clients/notifications",
    }),
    unreadNotification() {
      let idxUnread = this.notifications.findIndex((it) => !it.isRead);
      if (idxUnread > -1) {
        return true;
      }
      return false;
    },
    getIcons() {
      if (this.unreadNotification) {
        return require("@/assets/icon-bell-active.svg");
      }
      return require("@/assets/icon-bell.svg");
    },
    role() {
      return this['auth::role'];
    },
  },
  mounted() {
    try {
      this.loadNotifications();
    } catch (_) {
      //
    }
  },
  methods: {
    ...mapActions({
      loadNotifications: "clients/loadNotifications",
      readNotification: "clients/readNotification",
    }),
    onReadNotification(item) {
      console.log("item", item)
      this.readNotification(item.id).then(() => {
        if (item.title.toLowerCase().includes("personalized meal plan")) {
          return this.$router.push({
            name: "meal-plan.profile-gizi"
          })
        }
        if (this.role === 'client' && item.redirectPage === "progress-tracker" && item.redirectType === "feedback") {
          return this.$router.push({
            name: "client.progress-tracker.detail",
            params: { progressId: item.redirectId },
            query: { stepi: 2 }
          })
        } else if (this.role === 'nutritionist' && item.redirectPage === "progress-tracker" && item.redirectType === "feedback") {
          return this.$router.push(item.url);
        }
        if (item.redirectPage === "content-and-challenge") {
          return this.$router.push({
            name: "content-challenge.detail",
            params: { id: item.redirectId },
          });
        }

      });
    },
    getFormatDate(date) {
      moment.locale("id");
      return this.capitalizeFirstLetter(moment(date).fromNow());
    },
    capitalizeFirstLetter(string = "") {
      return string?.charAt(0).toUpperCase() + string?.slice(1);
    },
  },
};
</script>
