<template>
  <c-list-item
    :marginRight="[null, '30px']"
    textAlign="center"
    :display="['none', 'flex']"
  >
    <c-menu pos="relative">
      <c-menu-button
        as="router-link"
        to="/cart"
        display="flex"
        backgroundColor="transparent"
        flexDirection="column"
        textAlign="center"
        h="auto"
        v-chakra="{
          ':hover': {
            backgroundColor: 'transparent',
          },
          ':focus': {
            outline: 'none',
            boxShadow: 'none',
            background: 'none !important',
          },
        }"
      >
        <c-image src="/images/icon-cart.png" mx="auto" mb="5px" />
        <c-text
          fontFamily="Roboto"
          fontSize="12px"
          lineHeight="18px"
          fontWeight="400"
        >
          Keranjang
        </c-text>
      </c-menu-button>
    </c-menu>
  </c-list-item>
</template>

<script>
export default {
  name: "HeaderKeranjang",
};
</script>
