var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('c-list-item',{attrs:{"marginRight":[null, '30px'],"textAlign":"center","display":['none', 'flex']}},[_c('c-menu',{attrs:{"pos":"relative"}},[_c('c-menu-button',{directives:[{name:"chakra",rawName:"v-chakra",value:({
        ':hover': {
          backgroundColor: 'transparent',
        },
        ':focus': {
          outline: 'none',
          boxShadow: 'none',
          background: 'none !important',
        },
      }),expression:"{\n        ':hover': {\n          backgroundColor: 'transparent',\n        },\n        ':focus': {\n          outline: 'none',\n          boxShadow: 'none',\n          background: 'none !important',\n        },\n      }"}],attrs:{"as":"router-link","to":"/cart","display":"flex","backgroundColor":"transparent","flexDirection":"column","textAlign":"center","h":"auto"}},[_c('c-image',{attrs:{"src":"/images/icon-cart.png","mx":"auto","mb":"5px"}}),_c('c-text',{attrs:{"fontFamily":"Roboto","fontSize":"12px","lineHeight":"18px","fontWeight":"400"}},[_vm._v(" Keranjang ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }