var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('c-list-item',{attrs:{"textAlign":"center","display":['none', 'flex'],"alignItems":"center"}},[_c('c-menu',{attrs:{"pos":"relative"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isOpen = ref.isOpen;
return [_c('c-menu-button',{directives:[{name:"chakra",rawName:"v-chakra",value:({
        ':hover': {
          backgroundColor: 'transparent !important',
        },
        ':focus': {
          outline: 'none',
          boxShadow: 'none !important',
          background: 'none !important',
        },
      }),expression:"{\n        ':hover': {\n          backgroundColor: 'transparent !important',\n        },\n        ':focus': {\n          outline: 'none',\n          boxShadow: 'none !important',\n          background: 'none !important',\n        },\n      }"}],attrs:{"display":"flex","backgroundColor":"transparent","textAlign":"center","is-active":isOpen,"right-icon":"chevron-down","paddingLeft":[null, '30px'],"paddingRight":[null, '50px'],"borderLeft":"1px solid #F2F2F2"}},[_c('c-text',{attrs:{"display":['none', 'block'],"fontFamily":"Roboto","fontSize":"18px","fontWeight":"400","color":"#555555"}},[_vm._v(" "+_vm._s(_vm.fullName)+" ")]),_c('c-box',{attrs:{"pos":"absolute","top":"0","right":"0","w":"38px","h":"38px","overflow":"hidden","borderRadius":"100%","bg":"superLightGray.900"}},[_c('c-image',{attrs:{"w":"100%","h":"100%","object-fit":"cover","src":_vm.getPhotoUser(_vm.user.photoUrl),"alt":_vm.fullName}})],1)],1),_c('c-menu-list',{attrs:{"marginLeft":"8px !important","marginTop":"18px !important","boxShadow":"0px 5px 30px 0px #00000026","border":"none"}},[_c('c-menu-item',{directives:[{name:"chakra",rawName:"v-chakra",value:({
          ':hover': {
            backgroundColor: 'transparent !important',
          },
          ':focus': {
            outline: 'none',
            boxShadow: 'none',
            background: 'none !important',
          },
        }),expression:"{\n          ':hover': {\n            backgroundColor: 'transparent !important',\n          },\n          ':focus': {\n            outline: 'none',\n            boxShadow: 'none',\n            background: 'none !important',\n          },\n        }"}],attrs:{"px":"10px","borderBottom":"1px solid #F2F2F2"}},[_c('c-link',{attrs:{"display":"block","px":"10px","py":"10px","w":"100%","fontSize":"18px","color":"black.900","as":"router-link","to":{ name: 'client.profile.detail' }}},[_vm._v(" Lihat Profile ")])],1),(!_vm.role.includes('admin'))?_c('c-menu-item',{directives:[{name:"chakra",rawName:"v-chakra",value:({
          ':hover': {
            backgroundColor: 'transparent !important',
          },
          ':focus': {
            outline: 'none',
            boxShadow: 'none',
            background: 'none !important',
          },
        }),expression:"{\n          ':hover': {\n            backgroundColor: 'transparent !important',\n          },\n          ':focus': {\n            outline: 'none',\n            boxShadow: 'none',\n            background: 'none !important',\n          },\n        }"}],attrs:{"px":"10px","borderBottom":"1px solid #F2F2F2"}},[_c('c-link',{attrs:{"display":"block","px":"10px","py":"10px","w":"100%","fontSize":"18px","color":"black.900","as":"router-link","to":{ name: 'client.profile.settings' }}},[_vm._v(" Pengaturan ")])],1):_vm._e(),_c('c-menu-item',{directives:[{name:"chakra",rawName:"v-chakra",value:({
          ':hover': {
            backgroundColor: 'transparent !important',
          },
          ':focus': {
            outline: 'none',
            boxShadow: 'none',
            background: 'none !important',
          },
        }),expression:"{\n          ':hover': {\n            backgroundColor: 'transparent !important',\n          },\n          ':focus': {\n            outline: 'none',\n            boxShadow: 'none',\n            background: 'none !important',\n          },\n        }"}],attrs:{"px":"10px"}},[_c('c-button',{attrs:{"variant":"ghost","m":"0","w":"100%","display":"block","px":"10px","py":"10px","fontSize":"18px","color":"#FF0000","text-align":"left","font-weight":"normal"},on:{"click":function($event){return _vm.logout()}}},[_vm._v(" Log Out ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }