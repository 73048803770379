<template>
  <c-image alt="logo"
      :h="['24px', '32px']"
      :src="require('@/assets/logo-colored/logo-color.png')"
      :srcset="`
            ${require('@/assets/logo-colored/logo-color.png')} 1x,
            ${require('@/assets/logo-colored/logo-color@2x.png')} 2x,
            ${require('@/assets/logo-colored/logo-color@3x.png')} 3x,
          `"
  />
</template>

<script>
export default {
  name: 'logo-colored',
}
</script>

<style scoped>

</style>
