<template>
  <c-flex flex-dir="column" align-items="center" justify-content="space-between" h="55px">
    <c-image h="32px" w="32px" :src="icon" alt="icon challenge" />
    <c-text
      font-size="12px"
      font-weight="400"
      line-height="18px"
      text-align="center"
    >
      {{ label }}
    </c-text>
  </c-flex>
</template>

<script>
export default {
  name: "header-menu-item",
  props: ["label", "icon"],
};
</script>

<style scoped></style>
